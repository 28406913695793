<template>
  <div class="wrap">
    <div>
      <div class="conent_wrap">
        <div class="conent_item">
          <div class="conent_left">
            <div style="width: 100%; height: 350px" ref="pillar_a"></div>
          </div>
          <div class="conent_right">
            <div class="xQing">
              <div>
                <h3>用户详情</h3>
                <div class="conent_resume">
                  <div
                    @click="deal(item)"
                    v-for="(item, index) in user_data"
                    :key="index"
                  >
                    <p>
                      <img
                        src="../../assets/images/data_board/用户.png"
                        alt=""
                      />
                      {{ item.title }}
                    </p>
                    <p>{{ item.value }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="conent_item">
          <div class="conent_left">
            <div style="width: 100%; height: 350px" ref="pillar_b"></div>
          </div>
          <div class="conent_right">
            <div class="xQing">
              <div>
                <h3>今日用户下载</h3>
                <div class="conent_resume">
                  <div
                    @click="deal(item)"
                    v-for="(item, index) in user_status"
                    :key="index"
                  >
                    <p>
                      <img
                        src="../../assets/images/data_board/当日挂号.png"
                        alt=""
                      />{{ item.title }}
                    </p>
                    <p>{{ item.value }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="conent_item">
          <div class="conent_left">
            <div style="width: 100%; height: 350px" ref="resume_city_A"></div>
          </div>
          <div class="conent_right">
            <div class="xQing">
              <div>
                <h3>简历地区详情</h3>
                <div class="conent_resume">
                  <div
                    v-for="(item, index) in resume_place_list"
                    :key="index + item"
                    @click="tiao(item)"
                  >
                    <p>
                      <img
                        src="../../assets/images/data_board/简历.png"
                        alt=""
                      />{{ item.title }}
                    </p>
                    <p>{{ item.value }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="conent_item">
          <div class="conent_left">
            <div style="width: 100%; height: 350px" ref="resume_city_B"></div>
          </div>
          <div class="conent_right">
            <div class="xQing">
              <div>
                <h3>线索地区详情</h3>
                <div class="conent_resume">
                  <div
                    v-for="(item, index) in rader_place_list"
                    :key="index + item"
                  >
                    <p>
                      <img
                        src="../../assets/images/data_board/线路.png"
                        alt=""
                      />{{ item.title }}
                    </p>
                    <p>{{ item.value }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="conent_item">
          <div class="conent_left">
            <div style="width: 100%; height: 350px" ref="resume_city_c"></div>
          </div>
          <div class="conent_right">
            <div class="xQing">
              <div>
                <h3>近30日用户下载</h3>
                <div class="conent_resume">
                  <div
                    @click="deallink(item, '2')"
                    v-for="(item, index) in day_data"
                    :key="index"
                  >
                    <p>
                      <img
                        src="../../assets/images/data_board/数据 (1).png"
                        alt=""
                      />{{ item.title }}
                    </p>
                    <p>{{ item.value }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="conent_item">
          <div class="conent_left">
            <div style="width: 100%; height: 350px" ref="resume_city_d"></div>
          </div>
          <div class="conent_right">
            <div class="xQing">
              <div>
                <h3>近30日精英用户下载</h3>
                <div class="conent_resume">
                  <div
                    @click="deallink(item, '1')"
                    v-for="(item, index) in elite_user"
                    :key="index"
                  >
                    <p>
                      <img
                        src="../../assets/images/data_board/数据 (2).png"
                        alt=""
                      />{{ item.title }}
                    </p>
                    <p>{{ item.value }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  day_statistics,
  month_all_count,
  mon_all_elite,
  home_page,
  mon_day_dee,
  mon_day_de,
  resume_city_count,
} from "@/api/hr24_api/data_board.js";

import { data_line, data_pillar } from "@/utils/data_black.js";
let echarts = require("echarts/lib/echarts");
require("echarts/lib/chart/line");
require("echarts/lib/chart/bar");
require("echarts/lib/component/legend");
require("echarts/lib/component/title");
require("echarts/lib/component/tooltip");
let my_line = null;
let my_line1 = null;
let my_pillar_b = null;
let my_pillar_a = null;

let my_pillar_A = null;
let my_pillar_B = null;
let my_pillar_C = null;
let my_pillar_D = null;

export default {
  data() {
    return {
      dialogVisible: false,
      user_data: [
        {
          title: "用户总数",
          value: "--",
          type: "all_counts",
          src: "all_company",
        },
        {
          title: "正常用户",
          value: "--",
          type: "use_counts",
          src: "use_company",
        },
        {
          title: "到期数量",
          value: "--",
          type: "past_due_counts",
          src: "pass_due_company",
        },

        {
          title: "种子用户",
          value: "--",
          type: "agent_seed_user_count",
          src: "seed_user",
        },
        {
          title: "付费用户",
          value: "--",
          type: "agent_pay_user_count",
          src: "pay_user",
        },
        {
          title: "体验用户",
          value: "--",
          type: "agent_experience_user_count",
          src: "experience_user",
        },
        {
          title: "简历总数",
          value: "--",
          type: "big_resume_count",
          src: "big_resume_count",
          is_tiao: true,
        },
        {
          title: "线索总数",
          value: "--",
          type: "small_resume_count",
          src: "small_resume_count",
          is_tiao: true,
        },
        {
          title: "未简线索",
          value: "--",
          type: "small_resume_xiansuo",
          src: "small_resume_xiansuo",
          is_tiao: true,
        },
      ],
      user_status: [
        {
          title: "今日登录",
          value: "--",
          type: "login_in_count",
          id: 0,
          src: "login_in",
        },
        {
          title: "今日简历下载",
          value: "--",
          type: "download_count",
          id: 1,
          src: "download_resume",
        },
        {
          title: "今日完善简历",
          value: "--",
          type: "setting_resume_count",
          id: 1,
          src: "setting_resume",
        },
        {
          title: "今日投递简历",
          value: "--",
          type: "send_resume_count",
          id: 1,
          src: "send_resume",
        },
        {
          title: "今日添加JD",
          value: "--",
          type: "create_jd_count",
          id: 2,
          src: "create_jd",
        },
        {
          title: "今日完善公司",
          value: "--",
          type: "setting_company_count",
          id: 0,
          src: "setting_company",
        },

        {
          title: "今日雷达线索",
          value: "--",
          type: "resume_radar_count",
          id: 1,
          src: "radar_list",
        },
      ],

      day_data: [
        {
          title: "30日登录",
          value: "--",
          type: "login_in_count",
          id: 0,
          src: "login_in",
        },
        {
          title: "30日简历下载",
          value: "--",
          type: "down_resume_count",
          id: 1,
          src: "download_resume",
        },
        {
          title: "30日新增用户",
          value: "--",
          type: "create_user_count",
          id: 1,
          src: "create_user",
        },
        {
          title: "30日完善简历",
          value: "--",
          type: "setting_resume_count",
          id: 1,
          src: "setting_resume",
        },
        {
          title: "30日投递简历",
          value: "--",
          type: "send_resume_count",
          id: 1,
          src: "send_resume",
        },
        {
          title: "30日添加JD",
          value: "--",
          type: "create_jd_count",
          id: 2,
          src: "create_jd",
        },
        {
          title: "30日完善公司",
          value: "--",
          type: "setting_company_count",
          id: 0,
          src: "setting_company",
        },

        {
          title: "30日雷达线索",
          value: "--",
          type: "radar_count",
          id: 1,
          src: "radar_list",
        },
      ],

      elite_user: [
        {
          title: "30日登录",
          value: "--",
          type: "login_in_count",
          id: 0,
          src: "login_in",
        },
        {
          title: "今日登录",
          value: "--",
          type: "login_in_count",
          id: 0,
          src: "login_in",
          is_tiao: true,
        },
      ],
      resume_place_list: [],
      rader_place_list: [],
      resume_list: {}, //简历各个城市的分布
      rader_list: {}, //简历雷达各个城市的分布
    };
  },

  // 在html渲染之前 可以获取数据之类的
  created() {
    this.init();
  },

  // 在渲染html之后 方便dom操作
  mounted() {
    let that = this;
    this.$nextTick(() => {
      that.getA();
    });
  },

  methods: {
    init() {
      let obj = {
        show_data: "login_in",
      };

      let obj1 = {
        src: this.$route.query.src,
      };

      day_statistics(obj1).then((res) => {
        if (!res.code) {
          this.user_status = this.user_status.map((item) => {
            item.value = res.data[item.type];
            return item;
          });
        }
      });

      home_page(obj1).then((res) => {
        if (!res.code) {
          this.user_data = this.user_data.map((item) => {
            item.value = res.data[item.type];
            return item;
          });
          let arr_all = [];
          let arr_data = [];
          for (let key in res.data.all_place_data) {
            arr_all.push(key);
            arr_data.push(res.data.all_place_data[key]);
          }
          console.log(
            res.data.seed_place_data,
            res.data.experience_place_data,
            res.data.pay_place_data
          );

          this.all_place_data(arr_all, arr_data); //用户地区分布
          this.experience_place_data(this.$refs.pillar_b, "用户分布详情", [
            res.data.seed_place_data,
            res.data.experience_place_data,
            res.data.pay_place_data,
          ]); //体验用户分布
          // this.experience_place_data(
          //   this.$refs.pillar_A,
          //   '体验用户分布',
          //   res.data.experience_place_data
          // ) //体验用户分布
          // this.experience_place_data(
          //   this.$refs.pillar_B,
          //   '付费用户分布',
          //   res.data.pay_place_data
          // ) //体验用户分布
        }
        console.log(obj1);

        resume_city_count(obj1).then((res) => {
          console.log("计算简历总数--------222222222222-----");
          if (res.code == 0) {
            let resume_sum = 0;
            let radar_sum = 0;
            let add = res.data.city_list;
            this.resume_list = res.data.city_list;
            let add1 = res.data.radar_count;
            this.rader_list = res.data.radar_count;
            let arr1 = [];
            let data1 = [];
            let arr2 = [];
            let data2 = [];
            //计算简历总数
            console.log("计算简历总数-------------------------");
            console.log(add);

            for (let i in add) {
              this.resume_place_list.push({
                title: i,
                value: add[i],
              });
            }
            this.resume_echarts(this.$refs.resume_city_A, "简历地区分布", add);

            this.user_data[6].value = res.data.resume_all_count;

            //计算线索总数
            for (let i in add1) {
              this.rader_place_list.push({
                title: i,
                value: add1[i],
              });
              radar_sum += add1[i];
            }
            this.user_data[7].value = res.data.radar_all_count;
            this.user_data[8].value = res.data.wjxs_count;
            this.resume_echarts(this.$refs.resume_city_B, "线索地区分布", add1);
          }
        });
      });

      month_all_count().then((res) => {
        if (!res.code) {
          this.day_data = this.day_data.map((item) => {
            item.value = res.data[item.type];
            return item;
          });
        }
      });
      mon_all_elite(obj).then((res) => {
        if (!res.code) {
          this.elite_user[0].value = res.data.login_in_count;
        }
      });

      mon_day_dee({ show_data: "login_in" }).then((res) => {
        if (!res.code) {
          this.elite_user[1].value = res.data[29];
        }
      });
      let data = {
        show_data: "login_in",
      };

      mon_day_de(obj).then((res) => {
        if (!res.code) {
          this.getLink(this.$refs.resume_city_c, "30日登录", res);
        }
      });
      mon_day_dee(obj).then((res) => {
        if (!res.code) {
          this.getLink(this.$refs.resume_city_d, "30日精英版登录", res);
        }
      });
    },

    all_place_data(xdata, data) {
      console.log(xdata);

      console.log("用户分布");

      my_pillar_a = echarts.init(this.$refs.pillar_a);
      let data_pillar_B = data_pillar;
      data_pillar_B.title["text"] = "用户分布";
      data_pillar_B.color = "rgba(178,206,249,1)";
      // data_pillar_B.xAxis = { data: xdata }

      data_pillar_B.xAxis = {
        data: xdata,
        axisLabel: {
          interval: 0,
          rotate: 40,
        },
      };

      data_pillar_B.series = [
        {
          name: "数量",
          type: "bar",
          data: data,
          itemStyle: {
            normal: {
              label: {
                show: true, //开启显示
                position: "top", //在上方显示
                textStyle: {
                  //数值样式
                  color: "black",
                  fontSize: 16,
                },
              },
            },
          },
        },
      ];

      my_pillar_a.setOption(data_pillar_B);

      var self = this;

      //图标添加 单击事件
      my_pillar_a.on("click", function (param) {
        //点击的是哪个省
        console.log(param.name);

        self.goTo(param.name);
      });
    },

    goTo(key) {
      //直接跳转
      this.$router.push({ path: "/data_board", query: { src: key } });
      location.reload();
      this.$router.go(0);
    },

    experience_place_data(ref, text, add) {
      let arr_experience = [];
      let experience_data = [];

      let experience_data1 = [];

      let experience_data2 = [];
      for (let key in add[0]) {
        arr_experience.push(key);
        experience_data.push(add[0][key]);
      }
      for (let key in add[1]) {
        experience_data1.push(add[1][key]);
      }
      for (let key in add[2]) {
        experience_data2.push(add[2][key]);
      }

      my_pillar_b = echarts.init(ref);
      let data_pillar_B = data_pillar;
      data_pillar_B.title["text"] = text;
      data_pillar_B.color = ["rgba(92,221,166, 1)", "#FF9900", "#0066CC"];
      data_pillar_B.legend = {
        show: true,
        right: "200",
      };
      data_pillar_B.xAxis = {
        data: arr_experience,
        axisLabel: {
          interval: 0,
          rotate: 40,
        },
      };
      data_pillar_B.series = [
        {
          name: "种子用户",
          type: "bar",
          data: experience_data,

          // itemStyle: {
          //   normal: {
          //     label: {
          //       show: true, //开启显示
          //       position: 'top', //在上方显示
          //       textStyle: {
          //         //数值样式
          //         color: 'black',
          //         fontSize: 16,
          //       },
          //     },
          //   },
          // },
        },
        {
          name: "体验用户",
          type: "bar",
          data: experience_data1,

          // itemStyle: {
          //   normal: {
          //     label: {
          //       show: true, //开启显示
          //       position: 'top', //在上方显示
          //       textStyle: {
          //         //数值样式
          //         color: 'black',
          //         fontSize: 16,
          //       },
          //     },
          //   },
          // },
        },
        {
          name: "付费用户",
          type: "bar",
          data: experience_data2,
          // itemStyle: {
          //   normal: {
          //     label: {
          //       show: true, //开启显示
          //       position: 'top', //在上方显示
          //       textStyle: {
          //         //数值样式
          //         color: 'black',
          //         fontSize: 16,
          //       },
          //     },
          //   },
          // },
        },
      ];
      my_pillar_b.setOption(data_pillar_B);
    },
    resume_echarts(ref, text, add) {
      let arr_experience = [];
      let experience_data = [];

      for (let key in add) {
        arr_experience.push(key);
        experience_data.push(add[key]);
      }

      my_pillar_b = echarts.init(ref);
      let data_pillar_B = data_pillar;
      data_pillar_B.title["text"] = text;
      data_pillar_B.color = ["rgba(92,221,166, 1)"];

      data_pillar_B.xAxis = {
        data: arr_experience,
        axisLabel: {
          interval: 0,
          rotate: 40,
        },
      };
      data_pillar_B.series = [
        {
          name: "人数",
          type: "bar",
          data: experience_data,
          itemStyle: {
            normal: {
              label: {
                show: true, //开启显示
                position: "top", //在上方显示
                textStyle: {
                  //数值样式
                  color: "black",
                  fontSize: 16,
                },
              },
            },
          },
        },
      ];
      my_pillar_b.setOption(data_pillar_B);
    },
    getLink(ref, text, res) {
      my_pillar_b = echarts.init(ref);
      let data_pillar_B = data_pillar;
      data_pillar_B.title["text"] = text;
      data_pillar_B.color = ["rgba(92,221,166, 1)"];
      data_pillar_B.xAxis = {
        data: res.time_list,
        axisLabel: {
          interval: 0,
          rotate: 40,
        },
      };
      data_pillar_B.series = [
        {
          name: "人数",
          type: "line",
          data: res.data,
          itemStyle: {
            normal: {
              label: {
                show: true, //开启显示
                position: "top", //在上方显示
                textStyle: {
                  //数值样式
                  color: "black",
                  fontSize: 16,
                },
              },
            },
          },
        },
      ];
      my_pillar_b.setOption(data_pillar_B);
    },
    my_pillar_C(xdata, data) {
      my_pillar_C = echarts.init(this.$refs.pillar_C);
      let data_pillar_C = data_pillar;
      data_pillar_C.title["text"] = "30日登录";
      data_pillar_C.color = "rgba(92,221,166, 1)";
      data_pillar_C.xAxis = { data: xdata };
      data_pillar_C.series = [
        {
          name: "数量",
          type: "bar",
          data: data,
          itemStyle: {
            normal: {
              label: {
                show: true, //开启显示
                position: "top", //在上方显示
                textStyle: {
                  //数值样式
                  color: "black",
                  fontSize: 16,
                },
              },
            },
          },
        },
      ];
      my_pillar_C.setOption(data_pillar_C);
    },

    getA() {},
    deal(e, num) {
      if (!e.is_tiao) {
        if (Number(num)) {
          this.$router.push({
            path: "/data_table",
            query: { src: e.src, num: 1 },
          });
        } else {
          if (e.src == "cash_data") {
            this.dialogVisible = true;
          } else {
            this.$router.push({ path: "/data_table", query: { src: e.src } });
          }
        }
      } else {
        if (e.src == "big_resume_count") {
          this.$router.push({
            path: "/city_table",
            query: { src: "不限" },
          });
        }
        if (e.src == "small_resume_count") {
          this.$router.push({
            path: "/city_table",
            query: { src: "雷达" },
          });
        }
        if (e.src == "small_resume_xiansuo") {
          this.$router.push({
            path: "/city_table",
            query: { src: "未简雷达" },
          });
        }
      }
    },
    tiao(e) {
      if (!e.is_tiao) {
        this.$router.push({
          path: "/city_table",
          query: { src: e.title },
        });
      }
    },
    deallink(e, name) {
      if (!e.is_tiao) {
        console.log(e.src, 5325);
        this.$router.push({
          path: "/data_link",
          query: { src: e.src, title: e.title, name: name },
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
::-webkit-scrollbar {
  width: 5px; /*滚动条宽度*/
}
// .main .wrap {
//   max-width: -20%;
//   min-width: 1400px;
//   width: 80%;
//   margin-left: -200px;
// }
.wrap {
  padding-top: 54px;
}

.echarts {
  width: 100%;
}

.data_all {
  width: 50%;
}

.data_all > div {
  // height: 100px;
  // margin-bottom: 20px;
  // border-bottom: #000000 solid 1px;
  // overflow: hidden;
  display: flex;
  flex-flow: wrap;
  margin-left: 20px;
}

.data_all > div div {
  width: 150px;
  height: 80px;
  cursor: pointer;
}
.data_all > div div div:first-child {
  line-height: 20px;
  height: 20px;
  text-align: center;
  margin-bottom: 16px;
}
.data_all > div div div:last-child {
  height: 25px;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  color: $main2_color;
}
.xQing {
  width: 100%;
}
.xQing > div {
  overflow: hidden;
}

.conent_resume {
  display: -webkit-flex;
  display: flex;
  flex-flow: wrap;
}
.xQing > div > h3 {
  border-bottom: solid 1px #ccc;
  padding: 12px 0;
}
.xQing > div > h3:first-child {
  border-bottom: solid 1px #ccc;
  padding: 0px 0 12px 0;
}
.conent_resume > div {
  width: 150px;
  cursor: pointer;
}
.conent_resume p {
  text-align: center;
}
.conent_resume p > img {
  display: block;
  float: left;
  width: 30px;
}
.conent_resume p:last-child {
  height: 25px;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  color: $main2_color;
}
.conent_wrap {
  width: 100%;
  .conent_item {
    width: 100%;
    overflow: hidden;
    .conent_left {
      float: left;
      width: 60%;
      padding-top: 36px;
    }
    .conent_right {
      float: left;
      width: 40%;
    }
  }
}
</style>
